import React from "react";
import { baseUrl } from "../api/baseUrl";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Box, CardActionArea } from "@mui/material";
// import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import CardNew from "./CardNew2";


const Recommended = ({ CategoryData }) => {
  const navigate = useNavigate();
  const [recommendedData, setRecommendedData] = useState([]);

  const items = JSON.parse(localStorage.getItem("R_ID"));

  let fd = new FormData();
  fd.append("R_ID", items);

  // let fd = new FormData();
  // fd.append("R_ID", "uxir6f66ic0wgkyrtgoc");
  const getdata = async () => {
    try {
      const result = await axios.post(`${baseUrl}/recomended_product.php`, fd);
      setRecommendedData(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  const mediacart = {
    height: "10rem",
    width: "10rem",
  };
  return (
    <Box mt={5} mb={12}>
      <h3 className="font-bold mb-2 text-xl mt-36 text-center">Recommended</h3>
      <div className="mx-auto bg-red-500 h-1 w-[80px] mb-5 rounded-lg" />

      
      <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 ">
      {recommendedData.map((item) => (
           
                <CardNew key={item.P_ID} p_id={item.P_ID} c_id={item.C_ID} img={item.P_Image} title={item.Product_Title} price={item.P_Price}/>
            
            ))}
      </div>
    </Box>
  );
};

export default Recommended;
