import { Box, Card, CardActionArea, CardMedia, Grid } from "@mui/material";
import React, { useEffect } from "react";
import CategoryData from "../Data/CategoryData";
import { useSelector, useDispatch } from "react-redux";
import { getDealProduct } from "../redux/slice/DealProductSlice";
import { useNavigate } from "react-router-dom";
import CardNew from "./CardNew";

const Special = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DealProduct = useSelector((state) => state.dealProdcut);

  useEffect(() => {
    dispatch(getDealProduct());
  }, [dispatch]);

  
  return (
    <Box mt={4}>
      <h3 className="font-bold text-xl mt-36 text-center mb-2 ">Special</h3>
      <div className="mx-auto bg-red-500 h-1 w-[80px] mb-5 rounded-lg" />

  
      <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
      {DealProduct.data.message === "No Data Found!"
          ? DealProduct.data.message
          : DealProduct.data.map((item) => (
           
                <CardNew key={item.D_ID} id={item.D_ID} img={item.Deal_Image} title={item.Deal_Name} price={item.D_Price}/>
            
            ))}
      </div>
    
    </Box>
  );
};

export default Special;
