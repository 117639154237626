import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const getProduct = createAsyncThunk("get/product", async (C_ID) => {
  const R_ID = JSON.parse(localStorage.getItem("R_ID"));

  let fd = new FormData();
  fd.append("R_ID", R_ID);
  fd.append("C_ID", `${C_ID}`);
  const res = await axios.post(`${baseUrl}/fetch_product.php`, fd);
  return res.data;
});

export const productSlice = createSlice({
  name: "prodcut",
  initialState: {
    data: [],
    itemQuatity: 1,

    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: {
    ////for productdetail list
    [getProduct.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.data.push({count: 1})
    },
    [getProduct.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { increase } = productSlice.actions;

export default productSlice.reducer;
