import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Box, CardActionArea } from "@mui/material";
// import p2 from "../images/p2.jpg";
import CategoryData from "../Data/CategoryData";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDealProduct } from "../redux/slice/DealProductSlice";
import SwiperCore, { Autoplay } from "swiper";
import { useNavigate } from "react-router-dom";

const PosterSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DealProduct = useSelector((state) => state.dealProdcut);

  useEffect(() => {
    dispatch(getDealProduct());
  }, [dispatch]);

  SwiperCore.use([Autoplay]);

  return (
    <>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1.3}
          centeredSlides={true}
          loop={true}
          speed={1200}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
        >
          {DealProduct.data.message === "No Data Found!"
            ? DealProduct.data.message
            : DealProduct.data.map((item) => (
                <SwiperSlide>
                  <div className="  ">
                    <Box
                      m={1}
                      component="img"
                      sx={{
                        borderRadius: "16px",
                        boxShadow: "2px 2px 1px 2px #b87f7f",
                        // height: 233,
                        width: 315,
                        // maxHeight: { xs: 198, md: 167 },
                        // maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={item.Deal_Image}
                    />
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </Box>
      {/* //......................................... */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
          centeredSlides={true}
          loop={true}
          speed={1200}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
        >
          {DealProduct.data.message === "No Data Found!"
            ? DealProduct.data.message
            : DealProduct.data.map((item) => (
                <SwiperSlide>
                  <div className="  ">
                    <Box className="border rounded-xl"
                      m={1}
                      component="img"
                      sx={{
                      
                        // height: 233,
                        width: 315,
                        maxHeight: { xs: 198, md: 167 },
                        // maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={item.Deal_Image}
                    />
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </Box>
    </>
  );
};

export default PosterSlider;
