import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const SignUp = createAsyncThunk("login/register", async (data) => {
  try {
    let fd = new FormData();
    fd.append("user_email", data.email);
    fd.append("user_name", data.name);
    fd.append("user_dob", data.DOB);
    fd.append("user_password", data.password);
    const res = await axios.post(`${baseUrl}/register_User.php`, fd);

    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const signUpSlice = createSlice({
  name: "signup",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: {
    [SignUp.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [SignUp.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [SignUp.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default signUpSlice.reducer;
