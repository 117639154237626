import {
  Elements,
  CardElement,
  useElements,
  useStripe,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, {useState, useEffect} from 'react';

const stripePromise = loadStripe("pk_test_51LolbEFxBMNStwgE5foVCFGvYB7Nn4fDv4a4cvrGgWidsfVpsxzqoiRZkpUviLLraOP8EpbRxaUxLg5eluB44I7200dKR20EBc");
const appearance = {
  theme: 'night'
};

const handleSubmit = (stripe, elements) => async () => {
  const cardElement = elements.getElement(CardElement);

  const {error, paymentMethod} = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
  });

  if (error) {
    console.log('[error]', error);
  } else {
    console.log('[PaymentMethod]', paymentMethod);
    // ... SEND to your API server to process payment intent
  }
};

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  
  return (
    <>
    <div style={{
      padding:"5%"
    }}>
      <CardElement />
      
      <center>
       <button onClick={handleSubmit(stripe, elements)} 
       style={{
        width:"25%",
        backgroundColor:"black",
        color:"white",
        padding:"4px",
        borderRadius:"12px",
       }}>Buy</button>
      </center>
    
      </div>
    </>
  );
}

const StripeGateway  = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default StripeGateway;