import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";

const qrcodeRegionId = "reader";
function QrRead() {
  const navigate = useNavigate();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("R_ID"));
    if (items === null) {
      const queryParams = new URLSearchParams(window.location.search);
      const R_ID = getParameterByName("R_ID", window.location.search);
      const T_NO = queryParams.get("T_NO");

      if (R_ID !== null && T_NO !== null) {
        localStorage.setItem("R_ID", JSON.stringify(R_ID));
        localStorage.setItem("T_NO", JSON.stringify(T_NO));
        navigate("/home");
        // window.location.reload();
      }
    } else {
      navigate("/home");
    }
  }, []);

  let randomNum = "";
  for (let i = 0; i < 20; ++i) {
    randomNum += Math.floor(Math.random() * 10);
  }

  function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  const queryParams = new URLSearchParams(window.location.search);
  const R_ID = getParameterByName("R_ID", window.location.search);
  const T_NO = queryParams.get("T_NO");

  const [scanResultWebCam, setScanResultWebCam] = useState("");

  const Ran_NO = randomNum;
  if (scanResultWebCam !== "") {
    const queryParams = new URLSearchParams(scanResultWebCam);

    const R_ID = getParameterByName("R_ID", scanResultWebCam);
    const T_NO = queryParams.get("T_NO");

    if (
      JSON.parse(localStorage.getItem("Ran_NO")) === "" ||
      JSON.parse(localStorage.getItem("Ran_NO")) === null
    ) {
      // alert("generating random number")
      localStorage.setItem("Ran_NO", JSON.stringify(Ran_NO));
    }
    localStorage.setItem("R_ID", JSON.stringify(R_ID));
    localStorage.setItem("T_NO", JSON.stringify(T_NO));

    if (R_ID !== null) {
      navigate("/home");
    }

    // window.location.replace(scanResultWebCam)
  }

  const handleErrorWebCam = (error) => {
    console.log(error);
  };
  const handleScanWebCam = (result) => {
    if (result) {
      setScanResultWebCam(result);
    }
  };

  return (
    <>
      <div className="max-w-md mx-auto">
        <QrReader
          delay={300}
          style={{ width: "100%" }}
          onError={handleErrorWebCam}
          onScan={handleScanWebCam}
        />
      </div>
    </>
  );
}

export default QrRead;
