import React from "react";
import AddToChartPage from "../components/AddToChartPage";

const Carts = () => {
  return (
    <div className="">
      <div className="bg-[#FFCB04] m-auto top-0 fixed w-full h-12 ">
        <h3 className="text-center mt-2 text-white font-bold text-lg">Cart</h3>
      </div>

      <AddToChartPage />
    </div>
  );
};

export default Carts;
