// import { baseUrl } from "../api/baseUrl";
import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
// import CategoryData from "../Data/CategoryData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCategory } from "../redux/slice/CategorySlice";

const cartres = {
  // height: {sm: '2rem' }
  backgroundColor: "red",
  margin: "5px",
  boxShadow: "0.5px 0.5px 0.5px 1px #380c0c ",
  padding: "5px",
  // color:"blue",
  // height: { xs: "55px" }
};
const image = {
  height: { xs: "10rem" },
  // object-fit: contain;
};

const CategoryCards = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.category.data);

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  let navigate = useNavigate();

  return (
    <div className="mt-7">
      <Swiper slidesPerView={3}>
        {data.message === "No Data Found!"
          ? data.message
          : data.map((item) => (
              <SwiperSlide className="mx-1">
                {/* <Card
                  className="bg-[#FFCB04]"
                  key={item.C_ID}
                  onClick={() => navigate(`/categorypage/${item.C_ID}`)}
                  sx={cartres}
                >
                  <CardActionArea>
                    <CardMedia
                      className=""
                      component="img"
                      image={item.Category_Image}
                      // alt="green iguana"
                      sx={image}
                    />
                    <h3 className="text-center mt-[5px] font-bold text-lg text-yellow-50 H-full min-h-[55px]">
                      {item.Category_Name}
                    </h3>
                  </CardActionArea>
                </Card> */}


                {/* here adding own card  */}
                <div  key={item.C_ID}  onClick={() => navigate(`/categorypage/${item.C_ID}`)}
                  sx={cartres} className="card cursor-pointer  w-full h-[250px] bg-gray-100  border-2 rounded-3xl overflow-hidden relative " >
                  <img src={item.Category_Image} className="w-full h-full object-cover " />
                  <h3 className="absolute bottom-1 left-1/2 transform -translate-x-1/2 translate-y-1 text-lg  sm:text-3xl font-bold bg-[#FFCB04]/[0.8] w-full py-2  text-center">{item.Category_Name}</h3>
                </div>
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
};

export default CategoryCards;
