import React from "react";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import BottomTab from "../components/BottomTab";
import { useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";
import { isAsyncThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { loginUser, logout } from "../redux/slice/LoginSlice";

const Logout = () => {
  const dispatch = useDispatch();
  // const items = JSON.parse(localStorage.getItem("User"));
  // const R_ID = JSON.parse(localStorage.getItem("R_ID"));

  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.removeItem("User");
    setTimeout(() => {
      localStorage.removeItem("T_NO");
      localStorage.removeItem("R_ID");
      navigate("/");
    }, 1000);
  };
  return (
    <Box>
      <div className=" h-[6rem] bg-[#FFCB04] rounded-b-[12rem] "></div>
      <div className="flex pt-3 justify-center h-[4rem]">
        <PersonPinIcon fontSize="large" />
      </div>
      <div className="grid justify-center p-1">
        <p className="h-10 w-[23rem] max-w-4xl m-2 rounded-lg border-2 border-black p-1">
          <span className="font-bold">Name: </span> <span>{`anonymous`}</span>
        </p>
        <p className="h-10 w-[23rem] m-2 rounded-lg border-2 border-black p-1">
          <span className="font-bold">Email:</span>
          <span> {`anonymous`}</span>
        </p>
        <p className="h-10 w-[23rem] m-2 rounded-lg border-2 border-black p-1">
          <span className="font-bold">DOB:</span> <span>{`anonymous`}</span>
        </p>
      </div>
      <div className="flex justify-center pt-7 ">
        {" "}
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{
            backgroundColor: "red",
            "&:hover": {
              backgroundColor: "red",
            },
          }}
          startIcon={<LogoutIcon />}
        >
          Logout
        </Button>
      </div>
      {/* <BottomTab /> */}
    </Box>
  );
};

export default Logout;
