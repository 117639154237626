import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const getCategory = createAsyncThunk("get/category", async () => {
  const items = JSON.parse(localStorage.getItem("R_ID")); // This will run dynamically to get the id
  //  const items = 'uxir6f66ic0wgkyrtgoc';

  let fd = new FormData();
  fd.append("R_ID", items);
  const res = await axios.post(`${baseUrl}/fetch_category.php`, fd);
  return res.data;
});

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: {
    ////for productdetail list
    [getCategory.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getCategory.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default categorySlice.reducer;
