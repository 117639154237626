// import p1 from "../images/p1.jpg";
// import p2 from "../images/p4.jpg";
// import p5 from "../images/p5.jpg";
// import p8 from "../images/p8.jpg";
// import p9 from "../images/p9.jpg";

const CategoryData = [
  {
    id: "1",
    category: "main dish",
    // image: p1,
    price: 22,
    amount: 0,
  },
  {
    id: "2",
    category: "soup",
    // image: p1,
    price: 43,
    amount: 0,
  },
  {
    id: "3",
    category: "Drink",
    // image: p2,
    price: 56,
    amount: 0,
  },
  {
    id: "4",
    category: "meat",
    // image: p9,
    price: 54,
    amount: 0,
  },
  {
    id: "6",
    category: "side plates",
    // image: p8,
    price: 65,
    amount: 0,
  },
  {
    id: "7",
    category: "soup",
    // image: p5,
    price: 45,
    amount: 0,
  },
];

export default CategoryData;
