import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
// import CategoryData from "../Data/CategoryData";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { dealAddcart } from "../redux/slice/CartSlice";
import { useSelector } from "react-redux";
import { getProduct } from "../redux/slice/ProductSlice";
import { getDealProduct } from "../redux/slice/DealProductSlice";
import { getDrink } from "../redux/slice/DrinkSlice";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AppBar from "@mui/material/AppBar";
import { getFlavor } from "../redux/slice/FlavorSlice";

const Flavor = ({ D_ID, setflavorRadio, flavorRadio }) => {
  const dispatch = useDispatch();
  const [radio, setradio] = useState("");

  const handleChange = (event) => {
    setflavorRadio(event.target.value);
  };

  const flavorProduct = useSelector((state) => state.flavor);
  const DealProduct = useSelector((state) => state.dealProdcut);

  const data = DealProduct.data.filter((v) => v.D_ID === D_ID);

  useEffect(() => {
    dispatch(getDealProduct());
    if (data.length > 0) {
      dispatch(getFlavor(data[0].Drink_ID));
      // dispatch(getDrink(data[0].Flavour_ID));
    }
  }, [dispatch]);

  return (
    <Box pt={4}>
      <p className="font-bold text-lg pl-1 p-1 ">Please Select Flavor</p>
      <Grid
        rowSpacing={3}
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        container
      >
        {flavorProduct.data.length > 0
          ? flavorProduct.data.map((value) => (
              <Grid item>
                <div
                  style={{ boxShadow: "2px 2px 3px 2px red" }}
                  className=" p-1 overflow-hidden pb-1 w-24 h-32 shadow-sm rounded-md  grid justify-center items-center"
                >
                  <FormControl>
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">
                          Drinks
                        </FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={flavorRadio}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value={value.Flr_Name}
                        control={<Radio />}
                        label={`${value.Flr_Name}`}
                      />
                    </RadioGroup>
                  </FormControl>
                  <img src={value.Flr_Image} width={75} alt="" />
                </div>

                {/* <span>{value.Drink_Name}</span> */}
              </Grid>
            ))
          : ""}
      </Grid>
    </Box>
  );
};

export default Flavor;
