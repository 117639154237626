import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const postOrder = createAsyncThunk("post/order", async (order) => {
  const res = await axios.post(
    `${baseUrl}/cart_order.php
  `,
    order
  );
  return res.data;
});

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: {
    ////for productdetail list
    [postOrder.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [postOrder.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [postOrder.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default orderSlice.reducer;
