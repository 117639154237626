import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const getDrink = createAsyncThunk("get/drink", async (Drink_R_ID) => {
  const items = JSON.parse(localStorage.getItem("R_ID")); // This will run dynamically to get the id
  //  const items = 'uxir6f66ic0wgkyrtgoc';

  let fd = new FormData();
  fd.append("R_ID", items);
  fd.append("Drink_R_ID", `${Drink_R_ID}`);
  // fd.append("Flr_R_ID", `${Drink_R_ID}`);
  const res = await axios.post(`${baseUrl}/fetch_drinks.php`, fd);
  return res.data;
});

export const drinkSlice = createSlice({
  name: "drink",
  initialState: {
    data: [],
    itemQuatity: 1,

    loading: false,
    error: false,
  },
  reducers: {
    increase: (state, action) => {
      // const { price } = action.payload;
      const itemIndex = state.data.find(
        (item) => item.value === action.payload.P_ID
      );

      if (itemIndex) {
        state.value[itemIndex].itemQuatity += 1;
        // state.totalPrice += price;
      }
    },
  },
  extraReducers: {
    ////for productdetail list
    [getDrink.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getDrink.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.data.push({count: 1})
    },
    [getDrink.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { increase } = drinkSlice.actions;

export default drinkSlice.reducer;
