import GooglePayButton from "@google-pay/button-react";
import React from "react";
import { googlePayment } from "./AddToChartPage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// https://developers.google.com/pay/api/web/overview
// https://developers.google.com/pay/api/web/guides/tutorial

const TablePayment = () => {
  const navigate = new useNavigate();
  const newCart = useSelector((state) => state.cart);
  var price = newCart.totalPrice;
  var merId = "BCR2DN4TQTXPTB3W";

  return (
    <>
      <h1>Pay your bill</h1>
      <hr />
      <GooglePayButton
        environment="TEST"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "example",
                  gatewayMerchantId: "exampleGatewayMerchantId",
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: `${merId}`,
            merchantName: "Muhammad Abdullah",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: `${price}`,
            currencyCode: "USD",
            countryCode: "US",
          },
          shippingAddressRequired: true,
          callbackIntents: ["SHIPPING_ADDRESS", "PAYMENT_AUTHORIZATION"],
        }}
        //Runs at the end
        onLoadPaymentData={(paymentRequest) => {
          console.log("Success", paymentRequest);
        }}
        // Runs on success (2)
        onPaymentAuthorized={(paymentData) => {
          console.log("Payment Authorised Success", paymentData);
          return { transactionState: "SUCCESS" };
        }}
        // Initialize (1) Run on Change Data too
        onPaymentDataChanged={(paymentData) => {
          console.log("On Payment Data Changed", paymentData);
          return {};
        }}
        onReadyToPayChange={(result) => {
          console.log("On Ready To Pay Change:", result);
        }}
        existingPaymentMethodRequired="false"
        buttonColor="black"
        buttonType="Buy"
      />

      {price}
    </>
  );
};

export default TablePayment;
