import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { useSelector, useDispatch } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Grid } from "@mui/material";
import TablePayment from "./GooglePay";

import {
  cartDecrement,
  cartIncrement,
  clearCart,
  dealAddcart,
} from "../redux/slice/CartSlice";
import { useEffect } from "react";
import { postOrder } from "../redux/slice/OrderSlice";
import { ToastContainer, toast, Zoom } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { increase } from "../redux/slice/ProductSlice";

const AddToChartPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newCart = useSelector((state) => state.cart);
  const [cart, setcart] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [totalpricee, settotalpricee] = useState(0);
  const items = JSON.parse(localStorage.getItem("User"));
  const Table_No = JSON.parse(localStorage.getItem("T_NO"));

  const timeElapsed = Date.now();
  const today = new Date();
  const date = today.toLocaleDateString();
  const time = today.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  // const cart = [];
  // cart.push(newCart.value);

  var order = {
    cart,
    // User_ID: items.User_ID,
    User_ID: JSON.parse(localStorage.getItem("Ran_NO")),
    //
    // Order_Date: "Ananomous",
    Order_Date: `${date}  ${time}`,
    Table_No: Table_No,
    Total_Item: newCart.cartQuantity,
    Total_Price: newCart.totalPrice,
    Order_status: "Pending",
    R_ID: JSON.parse(localStorage.getItem("R_ID")),
  };

  // let length = newCart.value.length;

  // for (let i = 0; i < length; i++) {
  //  cart.push(newCart.value[i]);
  // }

  useEffect(() => {
    setcart(newCart.value);
  }, [newCart]);

  const addCartStyle = {
    // maxWidth: "8rem",
    maxHeight: "7rem",
    margin: "2px",
  };

  const increment = (idd) => {
    const datai = cart.map((item) => {
      //
      if (item.id === idd.id) {
        // newCart.value.price=newCart.value.price+item.price;
        settotalpricee(item.price);
        return { ...item, qty: item.qty + 1 };
      } else {
        return item;
      }
    });
    setcart(datai);
    // setQuantity(quantity+1)
  };

  const decrement = (idd) => {
    if (idd.qty === 1) {
      const filterdata = cart.filter((i) => i.id !== idd.id);
      return setcart(filterdata);
    }

    const datai = cart.map((item) => {
      //
      if (item.id === idd.id) {
        return { ...item, qty: item.qty - 1 };
      } else {
        return item;
      }
    });

    setcart(datai);
    setQuantity(quantity - 1);
  };

  const handleClick = (order) => {
    if (newCart.value.length > 0) {
      dispatch(postOrder(order)).then(() => {
        dispatch(clearCart());
        toast.success("Order Placed", {
          position: "top-center",
          autoClose: 1000,
          transition: Zoom,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    } else {
      toast.error("cart is empty", {
        position: "top-center",
        autoClose: 1000,
        transition: Zoom,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      {/* <TablePayment price={newCart.totalPrice} /> */}

      <div className="H-full mb-52  mt-14">
        <div className=" ">
          {cart?.map((items) => (
            <Grid key={items.id} columns={2}>
              <Grid item xs={6}>
                <div className=" flex m-2 mb-5 border shadow justify-between shadow-gray-400 rounded-md  max-w-xl">
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      // image={items.P_Image ? items.P_Image : items.Deal_Image}
                      image={items.imgUrl}
                      alt="green iguana"
                      sx={addCartStyle}
                    />
                  </Card>
                  <div className="grid pl-2">
                    <span>{items.category}</span>
                    <span>{items.count}</span>
                    {/* <span>{items.P_Price ? items.P_Price : items.D_Price}$</span> */}
                    <span>{items.price}$</span>
                  </div>
                  <div className="w-24 flex justify-center pt-10 justify-items-center ">
                    <span className="mr-2">
                      <DoDisturbOnIcon
                        onClick={() => dispatch(cartDecrement({ items, cart }))}
                       
                        fontSize="small"
                      />
                    </span>
                    <span className="mr-2">{items.qty}</span>
                    <span
                      onClick={() => dispatch(cartIncrement({ items, cart }))}
                    >
                      <AddCircleIcon fontSize="small" />
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
      <div className="border-2 justify-items-center fixed  z-50 bottom-16 rounded-t-lg shadow-2xl shadow-black p-1 grid justify-center ">
        <div className="bg-white w-full text-center">
          {" "}
          <h3 className="">
            Total: {newCart.totalPrice + Number(totalpricee)}$
          </h3>
          <p>total items: {newCart.cartQuantity}</p>
        </div>

        <button
          // onCick={() => handleClick(order)}
          onClick={() => {
            <>
              {handleClick(order)}
              {/* {navigate("/googlepay")} */}
              {/* {navigate("/stripe")} */}
              {/* {navigate("/stripegateway")} */}
            </>;
          }}
          className="w-screen text-white text-lg font-bold rounded-t-md  p-3 bg-[#FFCB04] "
        >
          {" "}
          BUY NOW
        </button>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddToChartPage;
