import React, { useEffect } from "react";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
// import CategoryData from "../Data/CategoryData";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { addcart } from "../redux/slice/CartSlice";
import { useSelector } from "react-redux";
import { getProduct } from "../redux/slice/ProductSlice";
import { getDealProduct } from "../redux/slice/DealProductSlice";
import { ToastContainer, toast, Zoom } from "react-toastify";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "react-toastify/dist/ReactToastify.css";
import { Grid } from "@mui/material";
import { useState } from "react";
const ProductView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { C_ID, P_ID } = useParams();
  const productViewData = useSelector((state) => state.product.data);
  const addToCartData = useSelector((state) => state.cart);
  const DealProduct = useSelector((state) => state.dealProdcut);
  const items = JSON.parse(localStorage.getItem("R_ID"));
  const [radio1, setRadio1] = useState("");

  useEffect(() => {
    dispatch(getProduct(C_ID));
  }, [dispatch, C_ID]);

  const imageStyle = {
    maxHeight: { xs: "20rem", sm: "9rem" },
    maxWidth: { xs: "20rem", sm: "15rem" },
  };
  const cardStyle = {
    height: { xs: "40rem", sm: "19rem" },
    width: { xs: "20rem" },

    padding: "1rem",
  };

  const data = productViewData.filter((v) => v.P_ID === P_ID);
  if (data.length > 0) {
    var arry = {
      id: data[0].P_ID,
      title: data[0].Product_Title,
      price: radio1,
      imgUrl: data[0].P_Image,
      qty: 1,
      R_ID: items,
    };
  }
  const handleChange = (event) => {
    setRadio1(event.target.value);
  };

  const onclickhandle = () => {
    if (arry.price) {
      return dispatch(addcart(arry));
    } else {
      toast.error("Please Select price", {
        position: "top-center",
        autoClose: 1000,
        transition: Zoom,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems="center"
      justifyContent={"center"}
      margin="auto"
      // marginTop={"2rem"}
      padding={1}
    >
      {productViewData
        .filter((v) => v.P_ID === P_ID)
        .map((item) => (
          <Box p={2} >
            {/* <Badge
              className="h-9 flex w-full pb-1 pl-3 justify-between"
              badgeContent={addToCartData.value.length}
              color="error"
            >
              <span
                className="w-11 rounded-lg cursor-pointer pt-1 text-center bg-[#FFCB04] text-white"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon fontSize="small" />
              </span>
              <span
                className="w-11 rounded-lg cursor-pointer text-center bg-[#FFCB04]] text-white"
                onClick={() => navigate("/carts")}
              >
                <AddShoppingCartIcon />
              </span>
            </Badge> */}


            <Badge
           className="h-9 flex w-full pb-1 pl-3 justify-between "
          //  sx={{position:"fixed",right:"5px"}}
           badgeContent={addToCartData.value.length}
           color="error"
         >
           <span
           
             className="w-11 rounded-lg cursor-pointer text-center bg-[#FFCB04] text-white "
             onClick={() => navigate("/home")}
           >
             <ArrowBackIcon fontSize="small" />
           </span>
           <span
             className="w-11 rounded-lg cursor-pointer text-center bg-[#FFCB04] text-white"
             onClick={() => navigate("/carts")}
           >
             <AddShoppingCartIcon />
           </span>
         </Badge>
            <Box
              m={1}
              component="img"
              sx={{
                // borderRadius: "16px",
                // boxShadow:"2px 2px 1px 2px #b87f7f",
                // height: 233,
                width: 315,
                maxHeight: { xs: 250, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
              }}
              alt="The house from the offer."
              src={item.P_Image}
            />
            <div className="flex justify-between">
              <b>
                <span>{item.Product_Title}</span>
              </b>
              <b>
                <span>${item.P_Price}</span>
              </b>
            </div>
            <div>
              <p>{item.P_Description}</p>
            </div>
            <div>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={radio1}
                  onChange={handleChange}
                >
                  {item.P_Price && (
                    <FormControlLabel
                      value={item.P_Price}
                      control={<Radio />}
                      label={` ${item.Price1_Des}  ${item.P_Price}$`}
                    />
                  )}
                  {item.P_Price2 && (
                    <FormControlLabel
                      value={item.P_Price2}
                      control={<Radio />}
                      label={`${item.Price2_Des}  ${item.P_Price2}$ `}
                    />
                  )}
                  {item.P_Price3 && (
                    <FormControlLabel
                      value={item.P_Price3}
                      control={<Radio />}
                      label={` ${item.Price3_Des}  ${item.P_Price3}$`}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </div>
            <div className="grid  justify-center  pt-5 ">
              <Button
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "red",
                  },
                }}
                size="large"
                className="text-white"
                onClick={() => onclickhandle()}
              >
                Add to cart
              </Button>
            </div>
            <ToastContainer />
          </Box>
          // <Card key={item.P_ID} sx={cardStyle}>
          //   <Badge
          //     className="h-9 flex w-full pb-1 justify-between"
          //     badgeContent={addToCartData.value.length}
          //     color="error"
          //   >
          //     <span
          //       className="w-11 rounded-lg cursor-pointer pt-1 text-center bg-red-700 text-white"
          //       onClick={() => navigate(-1)}
          //     >
          //       <ArrowBackIcon fontSize="small" />
          //     </span>
          //     <span
          //       className="w-11 rounded-lg cursor-pointer text-center bg-red-700 text-white"
          //       onClick={() => navigate("/carts")}
          //     >
          //       <AddShoppingCartIcon />
          //     </span>
          //   </Badge>
          //   <CardMedia
          //     component="img"
          //     image={item.P_Image}
          //     alt="green iguana"
          //     sx={imageStyle}
          //   />
          //   <div className="flex justify-between">
          //     <span>{item.Product_Title}</span>
          //     <span>${item.P_Price}</span>
          //   </div>

          //   <div className=""></div>

          //   <div className="grid  justify-center  pt-5">
          //     <Button
          //       variant="contained"
          //       color="error"
          //       className="text-white"
          //       onClick={() => dispatch(addcart(item))}
          //     >
          //       Add to cart
          //     </Button>
          //   </div>
          // </Card>
        ))}
    </Box>
  );
};

export default ProductView;
