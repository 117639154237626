import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
// import CategoryData from "../Data/CategoryData";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Box, Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { dealAddcart } from "../redux/slice/CartSlice";
import { useSelector } from "react-redux";
import { getProduct } from "../redux/slice/ProductSlice";
import { getDealProduct } from "../redux/slice/DealProductSlice";
import { getDrink } from "../redux/slice/DrinkSlice";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AppBar from "@mui/material/AppBar";
import Flavor from "../components/Flavor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DealProductView = () => {
  const [radio, setradio] = useState("");
  const [flavorRadio, setflavorRadio] = useState("");

  const handleChange = (event) => {
    setradio(event.target.value);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { D_ID } = useParams();
  const addToCartData = useSelector((state) => state.cart);
  const DealProduct = useSelector((state) => state.dealProdcut);
  const drinkProduct = useSelector((state) => state.drink);
  const items = JSON.parse(localStorage.getItem("R_ID"));


  const data = DealProduct.data.filter((v) => v.D_ID === D_ID);

  if (data.length > 0) {
    var arry = {
      id: data[0].D_ID,
      title: data[0].Deal_Name,
      price: data[0].D_Price,
      imgUrl: data[0].Deal_Image,
      Deal_Flavour: flavorRadio,
      Cool_Drink: radio,
      qty: 1,
      R_ID: items,
    };
  }

  useEffect(() => {
    dispatch(getDealProduct());
    console.log(data, "filter dataaaa");
    if (data.length > 0) {
      dispatch(getDrink(data[0].Drink_ID));
      // dispatch(getDrink(data[0].Flavour_ID));
    }
  }, [dispatch]);

  const imageStyle = {
    maxHeight: { xs: "20rem", sm: "9rem" },
    maxWidth: { xs: "20rem", sm: "15rem" },
  };
  const cardStyle = {
    height: { xs: "40rem", sm: "19rem" },
    width: { xs: "20rem" },

    padding: "1rem",
  };
  console.log(addToCartData.success, "derinkkk producttt");
  console.log(DealProduct, "dealproductt");
  console.log(radio, "valueee");

  const handleclick = async () => {
    try {
      const res = await dispatch(dealAddcart(arry));
      console.log(res, "ress dispatchh");
    } catch (error) {
      console.log(error, "catch errorrr");
    }
    console.log("errieeeeeeeeeee");
  };

  return (
   <div className="bg-gray-100 py-10 px-3 sm:px-[500px]"> <Box className="bg-white border shadow  px-2 rounded-lg "
     
    
   // marginTop={"2rem"}
   // padding={1}
 >
   {DealProduct.data
     .filter((v) => v.D_ID === D_ID)
     .map((item) => (
       <Box
         //  p={2}
         pt={1}
         // ml={1}
         pr={1}
       >
         <Badge
           className="h-9 flex w-full pb-1 pl-3 justify-between "
           sx={{position:"fixed",right:"5px"}}
           badgeContent={addToCartData.value.length}
           color="error"
         >
           <span
           
             className="w-11 rounded-lg cursor-pointer pt-1 text-center bg-[#FFCB04] text-white "
             onClick={() => navigate(-1)}
           >
             <ArrowBackIcon fontSize="small" />
           </span>
           <span
             className="w-11 rounded-lg cursor-pointer text-center bg-[#FFCB04] text-white"
             onClick={() => navigate("/carts")}
           >
             <AddShoppingCartIcon />
           </span>
         </Badge>
         <Box 
           m={1}
           ml={3}
           component="img"
           sx={{
             // borderRadius: "16px",
             // boxShadow:"2px 2px 1px 2px #b87f7f",
             // height: 233,
             width: 315,
             maxHeight: { xs: 250, md: 167 },
             // maxWidth: { xs: 350, md: 250 },
           }}
           alt="The house from the offer."
           src={item.Deal_Image}
         />
         <div className="flex justify-between bg-yellow-400">
           <b><span>{item.D_Title}</span></b>
           <b><span>${item.D_Price}</span></b>
         </div>
         <div className="p-3" style={{paddingRight:"15px"}}>

           {item.D_Description.split ('\n').map ((item, i) => <p key={i}>{item}</p>)}
           
         </div>
         <Box pt={4}>
           {drinkProduct.data.length > 0&&(<p className="font-bold text-lg pl-1 p-1 ">Please Select Drink</p>)}
           <Grid
             rowSpacing={3}
             spacing={2}
             direction="row"
             justifyContent="center"
             alignItems="center"
             container
           >
             {drinkProduct.data.length > 0
               ? drinkProduct.data.map((value) => (
                   <Grid item>
                     <div
                     
                       className=" p-2 overflow-hidden pb-1 w-28 h-32 shadow-lg border hover:border-yellow-400 rounded-lg  grid justify-center items-center"
                     >
                       <FormControl>
                         {/* <FormLabel id="demo-controlled-radio-buttons-group">
                       Drinks
                     </FormLabel> */}
                         <RadioGroup
                           aria-labelledby="demo-controlled-radio-buttons-group"
                           name="controlled-radio-buttons-group"
                           value={radio}
                           onChange={handleChange}
                         >
                           <FormControlLabel
                             value={value.Drink_Name}
                             control={<Radio />}
                             label={`${value.Drink_Name}`}
                           />
                         </RadioGroup>
                       </FormControl>
                       <img src={value.Drink_Image} width={75} alt="" />
                     </div>

                     {/* <span>{value.Drink_Name}</span> */}
                   </Grid>
                 ))
               : ""}
           </Grid>
         </Box>
         <div className="pb-28">
           {drinkProduct.data.length > 0&&<Flavor
             D_ID={D_ID}
             setflavorRadio={setflavorRadio}
             flavorRadio={flavorRadio}
           />}
         </div>

         <div className="  ">
         <Box
           // position={'fixed'}
           sx={{display:{xs: 'block', sm: 'none'}}}
           display={"flex"}
           justifyContent={"center"}
           pb={1}
         >
           <Button
             variant="contained"
             sx={{background:"red"}}
             size="large"
             className="text-white"
             onClick={() => handleclick()}
           >
             Add to cart
           </Button>
         </Box>
         </div>
         <Box
         sx={{display:{xs: 'none', sm: 'block'}}}
           // position={'fixed'}

           display={"flex"}
           justifyContent={"center"}
           pb={1}
         >
           <Button
           // fullWidth
             variant="contained"
             sx={{ backgroundColor: "red",
             "&:hover": {
               backgroundColor: "red"
             },
             "&:active": {
               backgroundColor: "red"
           },
           "&:click": {
             backgroundColor: "red"
         },
           }}
             size="large"
            

             onClick={() => handleclick()}
           >
             Add to cart
           </Button>
         </Box>
         <ToastContainer />
       </Box>
     ))}
 </Box></div>
  );
};

export default DealProductView;
