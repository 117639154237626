import React, { useEffect } from "react";
import QrRead from "./QrRead";

const CopyScan = () => {
  return (
    <div>
      {/* <QrCodeReader /> */}
      <div className="grid grid-cols-1 lg:grid-cols-3 h-[100vh] content-center justify-center">
        <div className="bg-gray-100"></div>
        <div className="bg-gray-100 ">
          {/* THIS COMPONENT RENDERS QR-CODE READER */}
          <QrRead />
        </div>
        <div className="bg-gray-100"></div>
      </div>
    </div>
  );
};

export default CopyScan;
