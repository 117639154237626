import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const HomeProtect = ({ children }) => {
  const items = JSON.parse(localStorage.getItem("R_ID"));
  if (!items) {
    return <Navigate to={"/"} />;
  }
  return children;
};

export default HomeProtect;
