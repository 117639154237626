import Home from "./pages/Home";
import Category from "./pages/Category";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductView from "./pages/ProductView";
import Carts from "./pages/Carts";
import OrderHistory from "./pages/OrderHistory";
import Logout from "./pages/Logout";
import Login from "./pages/Login";
import SingUp from "./pages/SingUp";
import ProtectedRoutes from "./protectedRoutes/ProtectedRoutes";
import PublicRoute from "./protectedRoutes/PublicRoute";
import QrCodeReader from "./components/QrCodeReader";
import DealProductView from "./pages/DealProductView";
import React from "react";
import QrScanner from "./pages/QrScanner";
import HomeProtect from "./protectedRoutes/HomeProtect";
import BottomProtect from "./protectedRoutes/BottomProtect";
import CopyScan from "./components/CopyScan";
import TablePayment from "./components/GooglePay";
import PaymentForm from "./components/StripePayment";
import StripeGateway from "./components/StripePaymentGateway";
import Redirector from "./pages/Redirector";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          {/* <Route element={<ProtectedRoutes />}> */}
          <Route element={<BottomProtect />}>
            {/* <Route element={<HomeProtect />}> */}
            <Route
              path="/home"
              element={
                <HomeProtect>
                  <Home />
                </HomeProtect>
              }
            />
            {/* <Route path="/qrr" element={<QrCodeReader />} /> */}
            <Route path="/qr" element={<Redirector />} />
            <Route path="/stripe" element={<PaymentForm />} />
            <Route path="/stripegateway" element={<StripeGateway />} />
            <Route path="/googlepay" element={<TablePayment />} />
            <Route path="/categorypage/:C_ID" element={<Category />} />
            <Route path="/productview/:C_ID/:P_ID" element={<ProductView />} />
            <Route path="/carts" element={<Carts />} />
            {/* BEFORE CAMERA QR PAGE */}
            <Route path="/" element={<QrScanner />} />
            {/* CAMERA PAGE  */}
            <Route path="/code" element={<CopyScan />} />
            <Route path="/orderhistory" element={<OrderHistory />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="*"
              element={
                <div className="w-50 mt-[200px] text-center text-3xl font-bold">
                  Invalid Try
                </div>
              }
            />
          </Route>

          <Route path="/dealproductview/:D_ID" element={<DealProductView />} />
          <Route path="/signup" element={<SingUp />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
