import { createSlice } from "@reduxjs/toolkit";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  value: [],
  cartQuantity: 0,
  totalPrice: 0,
  // itemQuatity: 1,
  success: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addcart: (state, action) => {
      // const { price } = action.payload;
      const itemIndex = state.value.find(
        (item) => item.id === action.payload.id
      );

      if (itemIndex) {
        toast.error("cart is already added", {
          position: "top-center",
          autoClose: 1000,
          transition: Zoom,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        state.success = false;
      } else {
        // const newItem = { ...state.value, cartQuantity: 1 };
        state.value.push(action.payload);
        state.cartQuantity = state.value.length;
        state.totalPrice += parseInt(action.payload.price, 10);

        // state.cartQuantity.push(action.payload);
      }
    },
    dealAddcart: (state, action) => {
      const itemIndex = state.value.find(
        (item) => item.id === action.payload.id
      );
      const iIndex = state.value.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex) {
        toast.error("cart is already added", {
          position: "top-center",
          autoClose: 1000,
          transition: Zoom,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        state.success = false;
      } else {
        // const newItem = { ...state.value, cartQuantity: 1 };
        state.value.push(action.payload);
        state.cartQuantity = state.value.length;
        state.totalPrice += parseInt(action.payload.price, 10);
        // state.value[iIndex].count += 1
        // state.cartQuantity.push(action.payload);
      }
    },

    cartIncrement: (state, action) => {
      const { items, cart } = action.payload;
      const datai = cart.map((item) => {
        //
        if (item.id === items.id) {
          return { ...item, qty: item.qty + 1 };
        } else {
          return item;
        }
      });
      state.value = datai;
      state.totalPrice += parseInt(items.price, 10);
      state.cartQuantity = state.value.length;
    },
    cartDecrement: (state, action) => {
      const { items, cart } = action.payload;
      if (items.qty === 1) {
        const filterdata = cart.filter((i) => i.id !== items.id);
        state.totalPrice -= parseInt(items.price, 10);

        state.value = filterdata;
        state.cartQuantity = state.value.length;

        return;
      }

      const datai = cart.map((item) => {
        //
        if (item.id === items.id) {
          return { ...item, qty: item.qty - 1 };
        } else {
          return item;
        }
      });

      state.value = datai;
      state.totalPrice -= parseInt(items.price, 10);
      state.cartQuantity = state.value.length;
    },
    clearCart: (state, action) => {
      // MAY BE NOT IN USE
      const removeItem = state.value.filter(
        (item) => item.id !== action.payload
      );

      state.value = [];
      // }else{
      // toast.error("cart is empty", {
      //   position: "top-center",
      //   autoClose: 1000,
      //   transition: Zoom,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      // });

      // }
    },
  },
});

export const { addcart, dealAddcart, cartIncrement, clearCart, cartDecrement } =
  cartSlice.actions;

export default cartSlice.reducer;
