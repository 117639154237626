import { Box } from "@mui/system";
import React, { useEffect } from "react";
import BottomTab from "../components/BottomTab";
import { useDispatch, useSelector } from "react-redux";
import { getOrderHistory } from "../redux/slice/OrderHistorySlice";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.orders.data);

  useEffect(() => {
    dispatch(getOrderHistory());
  }, [dispatch]);

  const changecolor = (item) => {
    if (item === "Pending") {
      return "red";
    }
    return "";
  };

  return (
    <div>
      <div className="bg-[#FFCB04] h-10 ">
        <h3 className="text-center text-white font-bold text-lg">
          Order History
        </h3>
      </div>
      <div>
        {data.error === undefined
          ? data.map((item) => (
              <Box
                m={1}
                p={1}
                pl={2}
                sx={{
                  boxShadow: "2px 3px 3px 3px grey ",
                  borderRadius: "10px",
                }}
              >
                <p>
                  <span className="mr-2">Name:</span>
                  <span>{item.Customer_Name}</span>
                </p>
                <p>
                  <span className="mr-2">Order ID:</span>
                  <span>{item.Order_ID}</span>
                </p>
                <p>
                  <span className="mr-2">Date Time:</span>
                  <span>{item.Order_Date}</span>
                </p>
                <p>
                  <span className="mr-2">Tabel No:</span>
                  <span>{item.Table_No}</span>
                </p>
                <div
                  className={`w-full flex justify-end
                ${
                  item.Order_status === "Pending"
                    ? " text-[red]"
                    : item.Order_status === "InProcess"
                    ? "text-[#1976d2]"
                    : "text-[green]"
                }
              `}
                >
                  <p>
                    {item.Order_status === "Pending" ? (
                      <AccessTimeOutlinedIcon color="error" />
                    ) : item.Order_status === "InProcess" ? (
                      <CachedOutlinedIcon color="primary" />
                    ) : (
                      <CheckCircleIcon color="success" />
                    )}
                    {item.Order_status}
                  </p>
                </div>
              </Box>
            ))
          : ""}
      </div>
      {/* <BottomTab /> */}
    </div>
  );
};

export default OrderHistory;
