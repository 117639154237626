import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const getFlavor = createAsyncThunk("get/flavor", async (Drink_R_ID) => {
  const items = JSON.parse(localStorage.getItem("R_ID")); // This will run dynamically to get the id

  let fd = new FormData();
  fd.append("R_ID", items);
  fd.append("Flr_R_ID", `${Drink_R_ID}`);
  const res = await axios.post(`${baseUrl}/fetch_Flavour.php`, fd);
  return res.data;
});

export const flavorSlice = createSlice({
  name: "flavor",
  initialState: {
    data: [],
    itemQuatity: 1,

    loading: false,
    error: false,
  },
  reducers: {
    increase: (state, action) => {
      // const { price } = action.payload;
      const itemIndex = state.data.find(
        (item) => item.value === action.payload.P_ID
      );

      if (itemIndex) {
        state.value[itemIndex].itemQuatity += 1;
        // state.totalPrice += price;
      }
    },
  },
  extraReducers: {
    ////for productdetail list
    [getFlavor.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getFlavor.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // state.data.push({count: 1})
    },
    [getFlavor.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { increase } = flavorSlice.actions;

export default flavorSlice.reducer;
