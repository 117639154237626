import { Box, Button, Container, Typography } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const QrScanner = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("R_ID"));
    // alert(items,"items")
    if (items === null) {
      navigate("/");
    } else {
      navigate("/home");
    }
  }, []);

  return (
    <Box
      display={"flex"}
      justifyContent="center"
      alignItems={"center"}
      // justifyItems={'center'}
      // margin='auto'
      height={"100vh"}
    >
      <Box
        display={"grid"}
        justifyContent="center"
        alignItems={"center"}
        justifyItems="center"
        width={"100%"}
        // border={1}
      >
        <Box
          //   border={1}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          width={"300px"}
          height={"300px"}
          borderRadius={1}
          sx={{ boxShadow: "3px 2px 3px 3px #b87f7f" }}
          mb={3}
          // onClick={()=>window.location.replace('https://scanapp.org/#reader')}

          onClick={() => navigate("/code")}
        >
          <QrCodeScannerIcon sx={{ width: "250px", height: "250px" }} />
        </Box>
        <Typography>Please Press on Icon to Scan Restaurant QR Code</Typography>
      </Box>

      {/* <BottomTab /> */}
    </Box>
  );
};

export default QrScanner;
