import React from "react";
import CategoryCards from "../components/CategoryCards";
import PosterSlider from "../components/PosterSlider";
import TopBar from "../components/TopBar";
import Recommended from "../components/Recommended";
import CategoryData from "../Data/CategoryData";
import Special from "../components/Special";

const Home = () => {
  // const r_id = JSON.parse(localStorage.getItem("R_ID"));

  return (
    <div>
      <TopBar />
      <PosterSlider />
      <CategoryCards />
      <Special />
      <Recommended CategoryData={CategoryData} />
    </div>
  );
};

export default Home;
