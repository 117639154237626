import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import BottomTab from "../components/BottomTab";

const BottomProtect = () => {
  return (
    <>
      <div>
        <Outlet />
      </div>
      <BottomTab />
    </>
  );
};

export default BottomProtect;
