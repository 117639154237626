import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";

function Redirector() {
  const navigate = useNavigate();
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("R_ID"));
    if (items === null) {
      const queryParams = new URLSearchParams(window.location.search);
      const R_ID = getParameterByName("R_ID", window.location.search);
      const T_NO = queryParams.get("T_NO");

      if (R_ID !== null && T_NO !== null) {
        localStorage.setItem("R_ID", JSON.stringify(R_ID));
        localStorage.setItem("T_NO", JSON.stringify(T_NO));
        navigate("/home");
        // window.location.reload();
      }
    } else {
      navigate("/home");
    }
  }, []);

  function getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  return <div>Redirecting</div>;
}

export default Redirector;
