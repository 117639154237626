import React from "react";
import { useNavigate } from "react-router-dom";
import "./BottommTab.css";
import { AiFillHome } from "react-icons/ai";
import { AiFillClockCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { useState } from "react";
import { GiBeachBag } from "react-icons/gi";
const BottomTab = () => {
  const navigate = useNavigate();
  const [Homme, setHomme] = useState(true);
  const [Shiip, setShiip] = useState(false);
  const [Ordeer, setOrdeer] = useState(false);
  const [Useer, setUseer] = useState(false);
  const [value, setValue] = React.useState(0);

  // MAYBE NOT IN USE. WILL DELETE IT IN REFACTOR
  const handleChange = (event, newValue) => {
    setValue(newValue);

    const tabsStyle = {
      color: "yellow",
    };
  };

  const HomeClick = () => {
    const items = JSON.parse(localStorage.getItem("R_ID"));
    if (items === null) {
      navigate("/");
    } else {
      navigate("/home");
    }

    setHomme(true);
    setOrdeer(false);
    setShiip(false);
    setUseer(false);
  };
  const ShipingClick = () => {
    navigate("/carts");
    setHomme(false);
    setOrdeer(false);
    setShiip(true);
    setUseer(false);
  };
  const OrderClick = () => {
    navigate("/orderhistory");
    setHomme(false);
    setOrdeer(true);
    setShiip(false);
    setUseer(false);
  };
  const UserClick = () => {
    navigate("/logout ");
    setHomme(false);
    setOrdeer(false);
    setShiip(false);
    setUseer(true);
  };
  return (
    <div className="mt-10 border-1  fixed z-50 bottom-0 w-full bg-[#FFCB04] h-[50px]">
      <div className=" w-full flex justify-evenly">
        <span className={Homme ? "bb" : ""}>
          <AiFillHome
            onClick={HomeClick}
            style={{ fontSize: "25px", color: "white" }}
          />
        </span>
        <span className={Shiip ? "bb" : ""}>
          <GiBeachBag
            onClick={ShipingClick}
            style={{ fontSize: "25px", color: "white" }}
          />
        </span>

        <span className={Ordeer ? "bb" : ""}>
          <AiFillClockCircle
            onClick={OrderClick}
            style={{ fontSize: "25px", color: "white" }}
          />
        </span>
        <span className={Useer ? "bb" : ""}>
          <FaUserAlt
            onClick={UserClick}
            style={{ fontSize: "25px", color: "white" }}
          />
        </span>
      </div>
    </div>
  );
};

export default BottomTab;
