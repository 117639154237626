import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Button, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/slice/LoginSlice";
import { InputAdornment } from "@material-ui/core";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import img from "./image/table.jpeg";
const schema = yup.object().shape({
  user_email: yup.string().required(),
  user_password: yup.string().required(),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const[clickKValue,setclickkValue]=useState(false);
  const result = useSelector((state) => state.login.data);

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //     const R_ID = queryParams.get("R_ID");
  //     const T_NO = queryParams.get("T_NO");
  //     localStorage.setItem("R_ID", JSON.stringify(R_ID));
  //     localStorage.setItem("T_NO", JSON.stringify(T_NO));
  //   }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    dispatch(loginUser(data));

    // setTimeout(() => {
    //   navigate('/')
    // }, 1000);

    // localStorage.setItem("User", JSON.stringify(result.user));
    // if (result.user) {

    // }
  };
  console.log(result.error, "resulttt.errorr");
  if (result.error === "0") {
    localStorage.setItem("User", JSON.stringify(result.user));
    console.log("uuuu", result.user);
    navigate("/");
  }
  const handleCClickShowPassword = () => {
    setclickkValue(!clickKValue);

  }
  return (
    <> 
    <div className="w-full flex justify-center mt-5">
    <img src={img} height="150px" width="150px" />
    </div>
    <form className="">
      <Box
        display={"flex"}
        flexDirection={"column"}
        // alignItems="center"
        justifyContent={"center"}
        margin="auto"
        marginTop={"2rem"}
        gap={2}
        padding={1}
      >
        <div className=" p-1 mb-2">
          <h1 className="font-bold text-lg">Welcome </h1>
          <p>Log In to continue</p>
        </div>

        <TextField
          size="small"
          type={"email"}
          name="user_email"
          variant="outlined"
          label="Email"
          {...register("user_email")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                < AiOutlineMail />
              </InputAdornment>
            )

          }}
        />
        <p className="text-[8px] w-[14rem] text-start mt-[-16px] text-yellow-400">
          {errors.user_email?.message}
        </p>

        <TextField
          size="small"
          type={!clickKValue ?"password" :"text"}
          name="user_password"
          variant="outlined"
          label="Password"
          {...register("user_password")}
          InputProps={{
              
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleCClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!clickKValue ? <VisibilityOff /> : <Visibility />}
                  {/* <Visibility/> */}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <p className="text-[8px] w-[14rem] text-start mt-[-16px] text-red-600">
          {errors.name?.message}
        </p>

        <Button
          // type="submit"
          onClick={handleSubmit(submitForm)}
          variant="contained"
          // size="small"

          // size="small"
          // autoCorrect="false"
          // autoSave="false"
          sx={{
            textTransform: "none",
            backgroundColor: "red",
            width:"100px",
            margin:"auto",
          }}
        >
          log In
        </Button>
        <p  style={{margin:"auto"}}>I'm new user&nbsp;
        <span
          onClick={() => navigate("/signup")}
          className="text-blue-800 underline "
          style={{cursor:"pointer"}}
        >
          Sign Up{" "}
        </span>
        </p>
      </Box>
    </form>
    </>
  );
};

export default Login;
