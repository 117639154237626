import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const loginUser = createAsyncThunk(
  "login/user",
  async (data, navigate) => {
    try {
      let fd = new FormData();
      fd.append("user_email", `${data.user_email}`);
      fd.append("user_password", `${data.user_password}`);
      const res = await axios.post(`${baseUrl}/User_Login.php`, fd);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {
    logout: (state, action) => {
      state.date = {};
    },
  },
  extraReducers: {
    ////for productdetail list
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [loginUser.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
