import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./slice/CartSlice";
import categoryReducer from "./slice/CategorySlice";
import productReducer from "./slice/ProductSlice";
import loginReducer from "./slice/LoginSlice";
import  DealProductReducer  from "./slice/DealProductSlice";
import orderHistoryReducer from './slice/OrderHistorySlice'
import drinkReducer from './slice/DrinkSlice'
import flavorReducer from './slice/FlavorSlice'
import orderReducer from './slice/OrderSlice'
import  signupReducer from './slice/SignUpSlice'

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    category: categoryReducer,
    product: productReducer,
    login: loginReducer,
    dealProdcut:DealProductReducer,
    orders: orderHistoryReducer,
    drink: drinkReducer,
    flavor: flavorReducer,
    order: orderReducer,
    signup: signupReducer
  },
});
