import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
// import CategoryData from "../Data/CategoryData";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import { getProduct } from "../redux/slice/ProductSlice";
import CardNew from "../components/CardNew";

const Category = () => {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.product.data);
  const { C_ID } = useParams();
  const navigate = useNavigate();

  const categoryData = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getProduct(C_ID));
  }, [dispatch, C_ID]);

  console.log(productData, "productttttttttts");
  console.log(categoryData, "categoryData in cart");

  // const finddd = () => {
  //   const newww = categoryData.filter((e) => e.C_ID === C_ID);
  //   console.log(categoryData, "lolaa newwwww");
  // };
  // finddd();

  const cardStyle = {
    height: "10rem",
    width: "10rem",
  };

  return (
    <div>
      <div className="h-15 fixed w-full top-[1px] z-10   bg-slate-300 ">
        <div className=" cursor-pointer p-2">
          <ArrowBackIcon onClick={() => navigate(-1)} />
        </div>
      </div>
      <Grid
        sx={{ marginTop: "3rem" }}
        alignItems="center"
        container
        justifyContent={"center"}
        spacing={2}
      >
        {productData.map((item) => (
          <Grid item key={item.P_ID} onClick={() => navigate(`/productview/${item.C_ID}/${item.P_ID}`)}>
            {/* <Card
              sx={{
                margin: "5px",
                boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
                borderRadius:"0 0 10px 10px" ,
                padding: "1px",
              }}
              onClick={() => navigate(`/productview/${item.C_ID}/${item.P_ID}`)}
            >
              <CardActionArea>
                <CardMedia
                  className=""
                  component="img"
                  image={item.P_Image}
                  alt="green iguana"
                  sx={cardStyle}
                />
                <h3 className="text-center h-24 grid items-end break-words w-[9rem] text-lg text-black">
                  {item.Product_Title}
                </h3>
                <p className="text-center pt-1 pb-1 "> ${item.P_Price}</p>
              </CardActionArea>
            </Card> */}
            <CardNew
              key={item.P_ID}
              price={item.P_Price}
              img={item.P_Image}
              
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Category;
