import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const getDealProduct = createAsyncThunk("get/dealProduct", async () => {
  let fd = new FormData();
  const items = JSON.parse(localStorage.getItem("R_ID")); // This will run dynamically to get the id

  fd.append("R_ID", items); // here in items we will add the qr code
  const res = await axios.post(`${baseUrl}/fetch_deal_product.php`, fd);
  return res.data;
});

export const DealProduct = createSlice({
  name: "dealProdcut",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: {
    ////for productdetail list
    [getDealProduct.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getDealProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getDealProduct.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default DealProduct.reducer;
