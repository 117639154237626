import React from "react";
import { Box } from "@mui/system";
import { Button, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { SignUp } from "../redux/slice/SignUpSlice";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { MdPassword } from "react-icons/md";
import { InputAdornment } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// import { CiUser } from "react-icons/ci";
const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  DOB: yup.string().required(),
  password: yup.string().required(),
  rePassword: yup.string().oneOf([yup.ref("password")]),
});
const SingUp = () => {
  const [clickValue, setclickValue] = useState(false);
  const [clickCValue, setclickCValue] = useState(false);
  const [datte, setdatee] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    dispatch(SignUp(data));
    navigate("/login");
  };
  const fieldstyle = {
    width: {
      xs: "252px",
      sm: "14rem",
    },
  };
  // const fieldSize = {
  //   xs: "large",
  //   sm: "small",
  // };
  const handleClickShowPassword = () => {
    setclickValue(!clickValue);
  };
  const handleCClickShowPassword = () => {
    setclickCValue(!clickCValue);
  };
  const handleFocus = () => {
    setdatee(!datte);
  };
  const handleunfocus = () => {
    setdatee(!datte);
  };
  return (
    <>
      <form className="" onSubmit={handleSubmit(submitForm)}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          // alignItems="center"
          justifyContent={"center"}
          margin="auto"
          marginTop={"2rem"}
          gap={2}
          padding={1}
        >
          <div className=" p-1 mb-2">
            <h1 className="font-bold text-lg">Welcome </h1>
            <p>Login to continue</p>
          </div>
          {/* <div style={{ display: "flex" }} > */}
          {/* <AiOutlineUser /> */}
          <TextField
            // style={{border:"none"}}
            {...register("name")}
            // < AiOutlineUser />
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <AiOutlineUser />
                </InputAdornment>
              ),
            }}
            // < AiOutlineUser />
            label="Please Enter Name"
            size="small"
            type={"text"}
            name="name"
            // label="Name"
            variant="outlined"
          />
          {/* </div> */}
          <p className="text-[8px] w-[14rem] text-start mt-[-10px] text-red-600">
            {errors.name?.message}
          </p>
          {/* <div style={{ display: "flex" }} > */}
          <TextField
            size="small"
            type={"email"}
            name="email"
            variant="outlined"
            label="Email"
            // placeholder="Enter Email"
            {...register("email")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <AiOutlineMail />
                </InputAdornment>
              ),
            }}
          />
          {/* </div> */}
          <p className="text-[8px] w-[14rem] text-start mt-[-10px] text-red-600">
            {errors.email?.message}
          </p>

          <TextField
            // sx={fieldstyle}
            size="small"
            type={datte ? "date" : "text"}
            name="DOB"
            variant="outlined"
            onFocus={handleFocus}
            //
            // placeholder="Phone Number"
            label=" Enter DOB"
            // label="Please Enter DOB"
            defaultChecked="jfkdjfd"
            {...register("DOB")}
          />

          <p className="text-[8px] w-[14rem] text-start mt-[-10px] text-red-600">
            {errors.DOB?.message}
          </p>

          <TextField
            size="small"
            type={!clickValue ? "password" : "text"}
            name="password"
            variant="outlined"
            {...register("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!clickValue ? <VisibilityOff /> : <Visibility />}
                    {/* <Visibility/> */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Enter Password"
          />
          <p className="text-[8px] w-[14rem] text-start mt-[-10px] text-red-600">
            {errors.password?.message}
          </p>

          <TextField
            size="small"
            name="repassword"
            type={!clickCValue ? "password" : "text"}
            variant="outlined"
            label="Enter Password"
            {...register("rePassword")}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       < MdPassword />
            //     </InputAdornment>
            //   )

            // }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleCClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!clickCValue ? <VisibilityOff /> : <Visibility />}
                    {/* <Visibility/> */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <p className="text-[8px] w-[14rem] text-start mt-[-10px] text-red-600">
            {errors.rePassword?.message}
          </p>
          <Button
            style={{ margin: "auto" }}
            type="submit"
            variant="contained"
            size="small"
            // size="small"
            sx={{
              backgroundColor: "red",
              width: "154px",
              textTransform: "none",
            }}
            // onClick={()=>navigate("/login")}
          >
            Singn Up
          </Button>
        </Box>
      </form>
    </>
  );
};

export default SingUp;
