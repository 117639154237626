import { baseUrl } from "../../api/baseUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

export const getOrderHistory = createAsyncThunk(
  "get/oderhistory",
  async () => {
  const R_ID = JSON.parse(localStorage.getItem("R_ID"));
      // const R_ID = 'uxir6f66ic0wgkyrtgoc'
      // const items = JSON.parse(localStorage.getItem("User"));
    let fd = new FormData();
    fd.append("R_ID", R_ID);
    fd.append("User_ID", JSON.parse(localStorage.getItem('Ran_NO')));
    const res = await axios.post(`${baseUrl}/fetch_user_orderStatus.php`, fd );
    return res.data;
  }
);

export const orderHistorySlice = createSlice({
  name: "orders",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: {
    ////for productdetail list
    [getOrderHistory.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getOrderHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getOrderHistory.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default orderHistorySlice.reducer;
