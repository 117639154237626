import { Button, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import OutputIcon from "@mui/icons-material/Output";

const TopBar = () => {
  const navigate = useNavigate();

  const buttonClick = () => {
    localStorage.removeItem("T_NO");
    localStorage.removeItem("R_ID");
    navigate("/");
  };
  const items = JSON.parse(localStorage.getItem("User"));
  return (
    <div className="w-full p-3 h-20 flex items-center justify-between">
      <p>
        {/* <b>Welcome! {items.user_name}</b> */}
        <b> Welcome User </b>
        {/* <b> User ID: {JSON.parse(localStorage.getItem('Ran_NO'))} </b> */}
      </p>

      {/* <IconButton  > */}
      <OutputIcon
        sx={{ color: "red" }}
        onClick={() => buttonClick()}
        fontSize="large"
      />
      {/* </IconButton> */}
    </div>
  );
};

export default TopBar;
